$(function() {
  // Nyla Cart
  let nylaCartLinks = $('a[data-action="nyla_add_to_cart"]')

  nylaCartLinks.click(function(e) {
    let variantIdReference = $(this).data('cart-variant-id');
    let quantityReference  = $(this).data('cart-quantity');

    window.parent.postMessage({
      type: 'NYLA_ADD_ALL_TO_CART',
      variants: [
        {
          variantId: variantIdReference,
          quantity: quantityReference
        }
      ]
    }, '*');

    return false;
  });

  // Product List: Items
  let productsItemsList = $('div.guidance-products .items-list-item');

  let listViewButton = $('[data-list="view"]');

  $.each(productsItemsList, function(idx,value) {
    let el = $(this);

    let listAddButton  = el.find('div[data-guidance-list-form="add-button"]');
    let listAddForm    = el.find('[data-guidance-list-form="add"]');

    // Hide the submit button
    let listAddFormSubmit = listAddForm.find('input[type="submit"]');
    listAddFormSubmit.hide();

    // Let the button trigger submit
    listAddButton.on('click', function() {
      $.ajax({
        url: listAddForm.attr('action'),
        dataType: 'json',
        type: listAddForm.attr('method'),
        data: listAddForm.serialize(),
        statusCode: {
          201: function(response, textStatus, xhr) {
            listViewButton.removeClass('visually-hidden');

            let successModalId = listAddButton.data('guidance-list-success-modal');
            let successModal   = $(successModalId);

            successModal.modal('show');
          }
        },
        error: function(resp) {
        }
      });

      return false;
    });
  });

  // List: Items
  let listItemsList      = $('div.guidance-list .guidance-list-item');
  let allListUpdateForms = $('[data-guidance-list-form="update"]');
  let allListDeleteForms = $('[data-guidance-list-form="delete"]');

  $.each(listItemsList, function(idx,value) {
    let el = $(this)

    let listDeleteForm = el.find('[data-guidance-list-form="delete"]');
    let listUpdateForm = el.find('[data-guidance-list-form="update"]');

    // Delete: Remove the panel
    let listDeleteFormSubmit = listDeleteForm.find('input[type="submit"]');

    listDeleteFormSubmit.on('click', function() {
      if(confirm('Are you sure?')) {
        let requestData   = listDeleteForm.serialize();
        let requestMethod = listDeleteForm.find('input[name="_method"]').val();

        allListUpdateForms.find(':input').prop('disabled', true);

        let listSubtotal  = $('.list-subtotal')

        $.ajax({
          url: listDeleteForm.attr('action'),
          dataType: 'json',
          type: requestMethod,
          data: requestData,
          statusCode: {
            204: function(response, textStatus, xhr) {
              el.fadeOut('slow', function() {
                el.remove();
                listSubtotal.remove();
                window.location.href = xhr.getResponseHeader('location');
              });
            },
            200: function(resp) {
              el.fadeOut('slow', function() {
                el.remove();
              });

              listSubtotal.html(resp.list_subtotal);

              allListUpdateForms.find(':input').prop('disabled', true);
            }
          },
          error: function(resp) {
          }
        });
      }

      return false;
    });

    // Update: Hide the submit button
    let listUpdateFormSubmit = listUpdateForm.find('input[type="submit"]');
    listUpdateFormSubmit.hide();

    // Handle Buttons
    let listUpdateBtnIncrement  = listUpdateForm.find('.btn-increment');
    let listUpdateBtnDecrement  = listUpdateForm.find('.btn-decrement');
    let listUpdateInputQuantity = listUpdateForm.find('.btn-input input[type="text"]');

    listUpdateBtnIncrement.on('click', function() {
      let newValue = parseInt(listUpdateInputQuantity.val()) + 1;

      if(newValue > 0) {
        listUpdateBtnDecrement.removeClass('disabled');
        listUpdateBtnDecrement.prop('disabled', false);
      }

      listUpdateInputQuantity.val(newValue);

      let requestData   = listUpdateForm.serialize();
      let requestMethod = listUpdateForm.find('input[name="_method"]').val();

      allListUpdateForms.find(':input').prop('disabled', true);

      $.ajax({
        url: listUpdateForm.attr('action'),
        dataType: 'json',
        type: requestMethod,
        data: requestData,
        success: function(resp) {
          let listItemSubtotal = el.find('.list-item-subtotal');
          let listSubtotal     = $('.list-subtotal')

          listItemSubtotal.html(resp.list_item_subtotal);
          listSubtotal.html(resp.list_subtotal);

          allListUpdateForms.find(':input').prop('disabled', false);

          if(newValue > 0) {
            listUpdateBtnDecrement.removeClass('disabled');
            listUpdateBtnDecrement.prop('disabled', false);
          }
        },
        error: function(resp) {
        }
      });

      return false;
    });

    listUpdateBtnDecrement.on('click', function() {
      let newValue = parseInt(listUpdateInputQuantity.val()) - 1;

      if(newValue == 0) {
        listUpdateBtnDecrement.addClass('disabled');
        listUpdateBtnDecrement.prop('disabled', true);
      }

      listUpdateInputQuantity.val(newValue);

      let requestData   = listUpdateForm.serialize();
      let requestMethod = listUpdateForm.find('input[name="_method"]').val();

      allListUpdateForms.find(':input').prop('disabled', true);

      $.ajax({
        url: listUpdateForm.attr('action'),
        dataType: 'json',
        type: requestMethod,
        data: requestData,
        success: function(resp) {
          let listItemSubtotal = el.find('.list-item-subtotal');
          let listSubtotal     = $('.list-subtotal')

          listItemSubtotal.html(resp.list_item_subtotal);
          listSubtotal.html(resp.list_subtotal);

          allListUpdateForms.find(':input').prop('disabled', false);

          if(newValue == 0) {
            listUpdateBtnDecrement.addClass('disabled');
            listUpdateBtnDecrement.prop('disabled', true);
          }
        },
        error: function(resp) {
        }
      });

      return false;
    });
  });

});
